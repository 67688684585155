import React from "react";
import styles from "./RelativeContainer.module.css";


function RelativeContainer({ children, darkContainer = true }) {
    const backgroundColor = darkContainer ? "var(--secondary-color)" : "var(--background-color)";
    const color = darkContainer ? "var(--text-color-light)" : "var(text-color-dark)";

    return (
        <div className={styles.relativeContainer} style={{ backgroundColor, color }}>
            {children}
        </div>
    );
}

export default RelativeContainer;
