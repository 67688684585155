import React, { useState, useEffect, useRef, useCallback } from 'react';
import styles from './NavigationBar.module.css';

import stylesLeftMenu from './LeftMenu/LeftMenu.module.css';
import sliderStyles from '../Slider/Slider.module.css';

import LeftMenu from './LeftMenu/LeftMenu';
import MainLinks from './Links/MainLinks';


const SWIPE_THRESHOLD = 100;
const REQUIRED_WIDTH = 425;


function NavigationBar({ links }) {
    const [showLeftMenu, setShowLeftMenu] = useState(false);

    const [startX, setStartX] = useState(null);
    const [endX, setEndX] = useState(null);
    const navRef = useRef(null);

    const handleCloseMenu = useCallback(() => {
        const leftMenu = document.querySelector(`.${stylesLeftMenu.leftMenu}`);
        leftMenu.classList.add(stylesLeftMenu.close);

        setTimeout(() => {
            leftMenu.classList.remove(stylesLeftMenu.close);
            setShowLeftMenu(false);
        }, 500);
    }, []);

    const handleHamburgerClick = useCallback(() => {
        setShowLeftMenu(show => !show);
    }, []);


    const handleTouchStart = useCallback((e) => {
        setStartX(e.touches[0].clientX);
    }, []);

    const handleTouchMove = useCallback((e) => {
        setEndX(e.touches[0].clientX);
    }, []);


    const handleTouchEnd = useCallback((e) => {
        const isSlider = e.target.closest(`.${sliderStyles.slider}`) !== null;

        if (!isSlider) {
            if (endX && startX && endX - startX > SWIPE_THRESHOLD) {
                setShowLeftMenu(true);
            } else if (endX && startX && startX - endX > SWIPE_THRESHOLD) {
                if (!showLeftMenu) return;
                handleCloseMenu();
            }
        }

        setStartX(null);
        setEndX(null);
    }, [endX, startX, handleCloseMenu, showLeftMenu]);

    useEffect(() => {
        if (window.innerWidth <= REQUIRED_WIDTH) {
            document.addEventListener('touchstart', handleTouchStart);
            document.addEventListener('touchmove', handleTouchMove);
            document.addEventListener('touchend', handleTouchEnd);
        }

        return () => {
            document.removeEventListener('touchstart', handleTouchStart);
            document.removeEventListener('touchmove', handleTouchMove);
            document.removeEventListener('touchend', handleTouchEnd);
        };
    }, [handleTouchStart, handleTouchMove, handleTouchEnd]);


    return (
        <div className={styles.navigationBar} ref={navRef}>
            <img src="/logo.png" alt="Logo de l'entreprise" />
            <nav>
                <ul className={styles.mainList}>
                    <MainLinks links={links} />
                </ul>
            </nav>
            <button className={styles.ctaButton}>Devis Gratuit</button>

            <div className={styles.hamburger} onClick={handleHamburgerClick}>
                <span className={styles.line} />
                <span className={styles.line} />
                <span className={styles.line} />
            </div>

            {showLeftMenu && <LeftMenu links={links} handleCloseMenu={handleCloseMenu} />}
        </div>
    );
}

export default NavigationBar;