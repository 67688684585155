import React from "react";

import Banner from "../../components/Banner/Banner";
import CallToAction from "../../components/CallToAction/CallToAction";
import RelativeWaveContainer from "../../components/Containers/RelativeWaveContainer/RelativeWaveContainer";
import ServicesSection from "../../components/ServicesSection/ServicesSection";
import Title from "../../components/Title/Title";
import FloatingContainer from "../../components/Containers/FloatingContainer/FloatingContainer";
import IconCaptionColumn from "../../components/IconCaption/IconCaptionColumn/IconCaptionColumn";

function Chauffage() {

    const titleProps = {
        text: "Installation et maintenance de sytemes de chauffage",
        img: {
            path: "/icons/fields/fire.png",
            alt: "Chauffage",
        },
        containerStyle: {
            width: "90%",
            justifyContent: "left",
        },
        titleStyle: {
            fontSize: "2rem",
            color: "var(--chauffage-color)",
            fontWeight: "700",
            textAlign: "left",
        },
        imgStyle: {
            width: "4rem",
            height: "auto",
        },
    };

    const servicesSectionProps = {
        titleComponent: <Title {...titleProps} />,
        services: [
            {
                title: "Installation de chauffages",
                description: `Nous proposons des solutions de chauffage adaptées pour toutes les structures. Nos experts en chauffage vous
                accompagnent dans le choix de la solution la plus adaptée à vos besoins et exigences. Nous installons des systèmes de chauffage
                performants pour garantir un air sain dans les espaces de travail et améliorer le confort des utilisateurs.`,
                image: "/images/services/chauffage/chaudiere.jfif",
                imageAlt: "Chaudière",
            },
        ],
    };

    const inlineIconCaptionProps = [
        {
            title: "Chauffage personnalisé",
            icon: "/icons/chauffage/radiateur.png",
            description: "Systèmes de chauffage sur mesure pour un confort optimal et des économies d'énergie."
        },
        {
            title: "Fiabilité et efficacité énergétique",
            icon: "/icons/chauffage/thermometre.png",
            description: "Un système de chauffage fiable et économe en énergie pour une chaleur constante et agréable."
        },
        {
            title: "Confort et économies d'énergie",
            icon: "/icons/chauffage/ampoule.png",
            description: "Solutions de chauffage performantes pour un confort maximal et des économies d'énergie significatives."
        },
    ];


    return (
        <div>
            <Banner
                title="Amélioration de votre confort thermique"
                image="/images/banners/chauffage.jpeg"
                imageAlt="Chauffage"
                borderColor="var(--chauffage-color)"
            />
            <FloatingContainer>
                <ServicesSection {...servicesSectionProps} />
            </FloatingContainer>
            <RelativeWaveContainer backgroundColor="#ffe0e0" >
                <Title
                    text="Améliorez votre confort thermique avec un système de chauffage performant"
                    titleStyle={{ fontSize: "2rem", marginBottom: "60px" }}
                    containerStyle={{ width: "60%", margin: "0 auto" }}
                />
                <IconCaptionColumn icons={inlineIconCaptionProps} />
            </RelativeWaveContainer>

            <CallToAction
                title="Vous souhaitez installer un système de chauffage ?"
                description="Nous vous accompagnons dans votre projet."
                buttonText="Contactez-nous"
                buttonLink="/contact"
                buttonColor="var(--chauffage-color)"
                buttonHoverColor="var(--chauffage-color-hover)"
            />
        </div>
    );
}

export default Chauffage;