import React, { useState } from "react";

import GroupRadio from "../../Form/Button/GroupRadio/GroupRadio";
import Input from "../../Form/Input/Input";
import TextArea from "../../Form/TextArea/TextArea";
import TagSelector from "../../TagSelector/TagSelector";


import styles from "./DetailedForm.module.css";

function DetailedForm() {
    const [firstName, setFirstName] = useState("");
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const options = [
        { id: 'devisOption', value: 'devis', label: 'Demande de devis' },
        { id: 'otherOption', value: 'autre', label: 'Autre' },
    ];

    const [displayTag, setDisplayTag] = useState(true);


    const handleRadioChange = (id) => {
        setDisplayTag(id[0] === "devisOption");
    };

    const inputStyle = {
        focus: {
            border: "1px solid var(--primary-color)",
        },
        input: {
            width: "100%",
            padding: "0.5rem",
            border: "1px solid var(--text-color-dark)",
            borderRadius: "0.5rem",
            marginBottom: "1rem",
            transition: "border 0.3s ease-in-out",
        },
        container: {
            marginBottom: "1rem",
            width: "100%",
        },
    };

    const textAreaStyle = {
        focus: {
            border: "1px solid var(--primary-color)",
        },
        input: {
            width: "100%",
            padding: "0.5rem",
            border: "1px solid var(--text-color-dark)",
            borderRadius: "0.5rem",
            transition: "border 0.3s ease-in-out",
        },
        container: {
            width: "100%",
        },
    };

    return (
        <div className={styles.detailedForm}>
            <h2 className={styles.title}>Une question ? Besoin d'un devis ?</h2>
            <p className={styles.description}>Remplissez le formulaire ci-dessous nous vous répondrons dans les plus bref délais.</p>
            <form action="">

                <div style={{ marginBottom: "1rem" }}>
                    <GroupRadio
                        options={options}
                        onChange={handleRadioChange}
                        defaultValue="devisOption"
                    />
                </div>

                {displayTag && (
                    <TagSelector
                        tags={['Chauffage', 'Climatisation', 'Froid', 'Energie solaire', 'Plomberie', 'Ventilation', 'Autre']}
                        onTagsChange={(tags) => console.log(tags)}
                    />
                )}

                <div className={styles.inlineInput}>
                    <Input
                        type="text"
                        id="firstName"
                        label="Prénom"
                        value={firstName}
                        onChange={(event) => setFirstName(event.target.value)}
                        style={inputStyle}
                        required
                    />

                    <Input
                        type="text"
                        id="lastName"
                        label="Nom"
                        value={firstName}
                        onChange={(event) => setFirstName(event.target.value)}
                        style={inputStyle}
                        required
                    />
                </div>

                <Input
                    type="tel"
                    id="phone"
                    label="Téléphone"
                    value={phone}
                    onChange={(event) => setPhone(event.target.value)}
                    style={inputStyle}
                    required
                />

                <Input
                    type="email"
                    id="email"
                    label="Email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    style={inputStyle}
                    required
                />

                <TextArea
                    id="message"
                    label="Message"
                    value={message}
                    onChange={(event) => setMessage(event.target.value)}
                    style={textAreaStyle}
                    required
                />
                <p className={styles.requiredNote}>Les champs avec <span className={styles.required}>(*)</span> sont obligatoire.</p>
                <button className={styles.submitBtn} type="submit">Envoyer</button>
            </form>
        </div>
    )
}


export default DetailedForm;