import React, { useState } from 'react';
import styles from './Input.module.css';

function Input({ type, id, value, onChange, style, required, label, placeholder }) {
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const inputStyle = {
        ...style.input,
        ...(isFocused ? style.focus : style.blur),
    };

    const containerStyle = { ...style.container };
    
    return (
        <div style={containerStyle} className={styles.inputContainer}>
            <label htmlFor={id}>{label}<span className={styles.required}>*</span></label>
            <input
                className={styles.input}
                type={type}
                id={id}
                value={value}
                onChange={onChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                style={inputStyle}
                placeholder={placeholder}
                required={required}
            />
        </div>
    );
}

export default Input;
