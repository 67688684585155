import React from "react";
import { NavLink } from "react-router-dom";

import styles from "./Card.module.css";

function Card({ title, description, image, alt, reverse = false, path = null, maxWidth, minHeight }) {

    const cardStyle = {
        maxWidth: maxWidth,
        minHeight: minHeight,
    };

    const imageContainer = (
        <div className={styles.imageContainer}>
            <img src={image} alt={alt} className={styles.image} />
        </div>
    );

    const textContainer = (
        <div className={styles.textContainer}>
            <h2>{title}</h2>
            <p dangerouslySetInnerHTML={{ __html: description }}></p>
        </div>
    );

    return (
        <div className={styles.card} style={cardStyle}>
            {reverse ? textContainer : imageContainer}
            {reverse ? imageContainer : textContainer}

            {path &&
                <div className={styles.button}>
                    <NavLink to={path} className={styles.link}>
                        En savoir plus
                    </NavLink>
                </div>
            }
        </div>
    );
}

export default Card;