import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from '../NavigationBar.module.css';

import SubLinks from './SubLinks';


function MainLinks({ links, closeLeftMenu }) {
    return (
        <>
            {links.map((link, index) => (
                <li key={index}>
                    {link.subLinks ? (
                        <>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a className={`${styles.navLink} ${styles.noLink}`} >{link.text}<div className={styles.arrowDown}></div></a>
                            <SubLinks subLinks={link.subLinks} closeLeftMenu={closeLeftMenu} />
                        </>
                    ) : (
                        <NavLink
                            to={link.path}
                            className={(navData) =>
                                navData.isActive
                                    ? `${styles.navLink} ${styles.active}`
                                    : styles.navLink
                            }
                            exact="true"
                            onClick={closeLeftMenu}
                        >
                            {link.text}
                        </NavLink>
                    )}
                </li>
            ))}

        </>
    );
}

export default MainLinks;