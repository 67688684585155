import { Route, Routes, useLocation } from 'react-router-dom';

// COMPONENTS
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import NavigationBar from './components/NavBar/NavigationBar';
import FloatingContactForm from './components/Contact/FloatingContactForm/FloatingContactForm';

// SCROLL TO TOP ON ROUTE CHANGE
import ScrollToTop from './components/ScrollToTop/ScrollToTop';

// PAGES
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Chauffage from './pages/services/Chauffage';
import Ventilation from './pages/services/Ventilation';
import Climatisation from './pages/services/Climatisation';
import EnergieSolaire from './pages/services/EnergieSolaire';


function App() {
	const location = useLocation();

	const links = [
		{
			path: '/',
			text: 'Accueil',
			subLinks: null,
			component: Home
		},
		{
			path: '/services',
			text: 'Services',
			component: null,
			subLinks: [
				{
					path: '/services/energie-solaire',
					text: 'Énergie solaire',
					component: EnergieSolaire,
				},
				{
					path: '/services/froid-climatisation',
					text: 'Froid et climatisation',
					component: Climatisation
				},
				{
					path: "/services/ventilation",
					text: "Ventilation",
					component: Ventilation,
				},
				{
					path: '/services/chauffage',
					text: 'Chauffage',
					component: Chauffage
				},
			]
		},
		{
			path: '/contact',
			text: 'Contact',
			subLinks: null,
			component: Contact
		},
		{
			path: '/a-propos',
			text: 'À propos',
			subLinks: null,
			component: About
		}
	];


	const getServicesLinks = (links) => {
		return links.map((link, _) => {
			return { path: link.path, text: link.text };
		});
	};


	const getContactFormColor = () => {

		// Define your color logic based on the current location
		if (location.pathname === '/services/ventilation') {
			// Color for the 'Services' route
			return {
				Foreground: 'var(--vmc-color)',
				Background: 'var(--vmc-color)',
				HoverColor: 'var(--vmc-color-hover)',
				FocusColor: 'var(--vmc-color)',
			};
		} else if (location.pathname === '/services/chauffage') {
			// Color for the 'Services' route
			return {
				Foreground: 'var(--chauffage-color)',
				Background: 'var(--chauffage-color)',
				HoverColor: 'var(--chauffage-color-hover)',
				FocusColor: 'var(--chauffage-color)',
			};
		} else if (location.pathname === '/services/froid-climatisation') {
			// Color for the 'Services' route
			return {
				Foreground: 'var(--climatisation-color)',
				Background: 'var(--climatisation-color)',
				HoverColor: 'var(--climatisation-color-hover)',
				FocusColor: 'var(--climatisation-color)',
			};
		} else if (location.pathname === '/services/energie-solaire') {
			// Color for the 'Services' route
			return {
				Foreground: 'var(--energie-solaire-color)',
				Background: 'var(--energie-solaire-color)',
				HoverColor: 'var(--energie-solaire-color-hover)',
				FocusColor: 'var(--energie-solaire-color)',
			};
		}

		// Default color if no specific route matches
		return {
			Foreground: 'var(--primary-color)',
			Background: 'var(--secondary-color)',
			HoverColor: 'var(--primary-color)',
			FocusColor: 'var(--primary-color)',
		};
	};


	return (
		<div className="App">
			<Header />
			<NavigationBar links={links} />
			<Routes>
				{links.map((link, index) => {
					if (link.subLinks) {
						return link.subLinks.map((subLink, subIndex) => (
							<Route key={subIndex} path={subLink.path} element={<subLink.component />} />
						));
					}
					else {
						return (
							<Route key={index} path={link.path} element={<link.component />} />
						);
					}
				})}
			</Routes>
			<ScrollToTop />
			<FloatingContactForm {...getContactFormColor()} />
			<Footer links={getServicesLinks(links[1].subLinks)} />
		</div>
	);
}


export default App;