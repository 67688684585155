import React, { useState } from "react";


function Submit({ Foreground, Background, HoverColor, FocusColor, text }) {

    const defaultStyle = {
        color: "#fff",
        backgroundColor: Background,
    };

    const hoverStyle = {
        color: "#fff",
        backgroundColor: HoverColor,
    };

    const focusStyle = {
        color: "#fff",
        backgroundColor: Background,
        border: `1px solid ${FocusColor}`,
    };


    const [isHovered, setIsHovered] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const handleHover = () => {
        setIsHovered(true);
    };

    const handleUnhover = () => {
        setIsHovered(false);
    };


    return (
        <button
            type="submit"
            style={isHovered ? hoverStyle : isFocused ? focusStyle : defaultStyle}
            onMouseEnter={handleHover}
            onMouseLeave={handleUnhover}
            onFocus={handleFocus}
            onBlur={handleBlur}
        >
            {text}
        </button>
    );
}

export default Submit;