import React from "react";

import styles from "./Description.module.css";

function Description({ title, description, image }) {

    const imgPosition = image.left ? styles.left : image.right ? styles.right : {};
    const imgStyle = {
        backgroundImage: `url(${image.src})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
    };

    return (
        <div className={styles.description}>
            <h2>{title}</h2>
            <div className={`${imgPosition}`}>
                <img className={styles.image} style={imgStyle} src={image.src} alt={image.alt} />
                <p dangerouslySetInnerHTML={{ __html: description }}></p>
            </div>
        </div>
    );
}

export default Description;