import React from "react";
import styles from "../Slider.module.css";


function SliderControls({ prevSlide, nextSlide }) {
  return (
    <>
      <div
        className={styles.slider__control + " " + styles.slider__controlLeft}
        onClick={prevSlide}
      ></div>
      <div
        className={
          styles.slider__control + " " + styles.slider__controlRight
        }
        onClick={nextSlide}
      ></div>
    </>
  );
}

export default SliderControls;