import React from "react";

import Banner from "../../components/Banner/Banner";
import CallToAction from "../../components/CallToAction/CallToAction";
import FloatingContainer from "../../components/Containers/FloatingContainer/FloatingContainer";
import RelativeWaveContainer from "../../components/Containers/RelativeWaveContainer/RelativeWaveContainer";
import IconCaptionColumn from "../../components/IconCaption/IconCaptionColumn/IconCaptionColumn";
import ServicesSection from "../../components/ServicesSection/ServicesSection";
import Title from "../../components/Title/Title";


function EnergieSolaire() {

    const titleProps = {
        text: "Installation et maintenance de systèmes d'énergie solaire",
        img: {
            path: "/icons/fields/sun.png",
            alt: "Énergie solaire",
        },
        containerStyle: {
            width: "90%",
            justifyContent: "left",
        },
        titleStyle: {
            fontSize: "2rem",
            color: "var(--energie-solaire-color)",
            fontWeight: "700",
            textAlign: "left",
        },
        imgStyle: {
            width: "4rem",
            height: "auto",
        },
    };

    const servicesSectionProps = {
        titleComponent: <Title {...titleProps} />,
        services: [
            {
                title: "Installation de panneaux solaires",
                description: `Nous proposons des solutions d'installation de panneaux solaires pour la production d'énergie solaire. Nos experts
                vous accompagnent dans le choix et l'installation de panneaux solaires adaptés à vos besoins pour une utilisation efficace de l'énergie solaire.`,
                image: "/images/services/energie-solaire/solar-panel.png",
                imageAlt: "Panneaux solaires",
            },
            {
                title: "Maintenance de systèmes d'énergie solaire",
                description: `Nous assurons la maintenance et le suivi de vos systèmes d'énergie solaire pour garantir leur bon fonctionnement et
                optimiser leur performance. Nos techniciens qualifiés effectuent des inspections régulières et des interventions en cas de besoin.`,
                image: "/images/services/energie-solaire/maintenance.png",
                imageAlt: "Maintenance solaire",
            },
        ],
    };

    const inlineIconCaptionProps = [
        {
            title: "Économies d'énergie",
            icon: "/icons/energie-solaire/money.png",
            description: "Réduction des coûts énergétiques grâce à l'utilisation de l'énergie solaire.",
        },
        {
            title: "Énergie renouvelable",
            icon: "/icons/energie-solaire/energie-ecologique.png",
            description: "Utilisation d'une source d'énergie renouvelable et respectueuse de l'environnement.",
        },
        {
            title: "Autonomie énergétique",
            icon: "/icons/energie-solaire/parametres.png",
            description: "Possibilité de devenir autonome en termes de production d'énergie.",
        },
    ];

    const callToActionProps = {
        title: "Vous souhaitez en savoir plus ?",
        description: "Contactez-nous pour en savoir plus sur nos services d'installation et de maintenance de systèmes d'énergie solaire.",
        buttonText: "Nous contacter",
        buttonLink: "/contact",
        buttonColor: "var(--energie-solaire-color)",
        buttonHoverColor: "var(--energie-solaire-color-hover)",
    };


    return (
        <div>
            <Banner
                title="Améliorez votre système avec nos solutions<br> d'énergie solaire"
                image="/images/banners/energie-solaire.jpg"
                alt="Énergie solaire"
                borderColor="var(--energie-solaire-color)"
            />
            <FloatingContainer>
                <ServicesSection {...servicesSectionProps} />
            </FloatingContainer>
            <RelativeWaveContainer backgroundColor="#fffcdb">
                <Title
                    text="Améliorez votre système avec nos solutions d'énergie solaire"
                    titleStyle={{ fontSize: "2rem", marginBottom: "60px" }}
                    containerStyle={{ width: "60%", margin: "0 auto" }}
                />
                <IconCaptionColumn icons={inlineIconCaptionProps} />
            </RelativeWaveContainer>
            <CallToAction {...callToActionProps} />
        </div>
    );

}

export default EnergieSolaire;