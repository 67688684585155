import { useEffect, useRef, useState } from "react";

import styles from "./Banner.module.css";

function Banner({ title, image, alt, borderColor }) {

    const titleRef = useRef(null);
    const [isBannerVisible, setIsBannerVisible] = useState(false);

    const bannerClasses = isBannerVisible
        ? `${styles.banner} ${styles.show}`
        : styles.banner;

    const beforeImageStyle = {
        '--banner-before-image': `url(${image})`,
        borderTop: `7px solid ${borderColor}`,
    };


    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    setIsBannerVisible(entry.isIntersecting);
                    if (entry.isIntersecting) {
                        entry.target.classList.add(styles.show);
                    } else {
                        entry.target.classList.remove(styles.show);
                    }
                });
            },
            { threshold: 0.5 }
        );

        observer.observe(titleRef.current);
        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <div className={bannerClasses} style={beforeImageStyle} alt={alt}>
            <div className={styles.banner__content}>
                <h1 className={styles.banner__title} ref={titleRef} dangerouslySetInnerHTML={{ __html: title }} />
            </div>
        </div>
    );
}

export default Banner;
