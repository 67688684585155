import React, { useState } from "react";

function Input({ type, id, value, onChange, style, required, label, placeholder }) {

    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    return (
        <div>
            <label htmlFor={id}>{label}<span className="required">*</span></label>
            <input
                type={type}
                id={id}
                value={value}
                onChange={onChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                style={isFocused ? style : {}}
                placeholder={placeholder}
                required={required}
            />
        </div>
    );
}

export default Input;