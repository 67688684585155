import React, { useState } from "react";
import styles from "./BasicForm.module.css";

import Input from "./Input/Input";
import TextArea from "./TextArea/TextArea";
import Submit from "./Submit/Submit";

function BasicForm({ Foreground, Background, HoverColor, FocusColor }) {

    const [firstName, setFirstName] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");

    const [message, setMessage] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        // code to submit form data to backend or API goes here
        setIsSubmitted(true);
    };

    const styleForegroundColor = Foreground ? { color: Foreground } : {};
    const styleFocusColor = FocusColor ? { border: `1px solid ${FocusColor}` } : {};

    return (
        <div className={styles.contactForm}>
            <h2 style={styleForegroundColor}>Contactez-nous</h2>
            <p>Une question ? Contactez-nous et nous vous répondrons dès que possible.</p>
            {isSubmitted ? (
                <p>Merci de nous avoir contacté ! Nous vous répondrons dès que possible.</p>
            ) : (
                <form onSubmit={handleSubmit}>
                    <div className={styles.formGroupInfos}>
                        <div className={styles.formGroup}>
                            <div className={styles.userName}>
                                <Input
                                    type="text"
                                    id="firstName"
                                    label="Prénom"
                                    value={firstName}
                                    onChange={(event) => setFirstName(event.target.value)}
                                    style={styleFocusColor}
                                    required
                                />
                            </div>
                            <div className={styles.userFamilyName}>
                                <Input
                                    type="text"
                                    id="name"
                                    label="Nom"
                                    value={name}
                                    onChange={(event) => setName(event.target.value)}
                                    style={styleFocusColor}
                                    required
                                />
                            </div>
                        </div>
                        <div className={styles.userEmail}>
                            <Input
                                type="email"
                                id="email"
                                label="Email"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                                style={styleFocusColor}
                                required
                            />
                        </div>
                    </div>
                    <div>
                        <TextArea
                            id="message"
                            label="Message"
                            value={message}
                            onChange={(event) => setMessage(event.target.value)}
                            style={styleFocusColor}
                            required
                        ></TextArea>
                        <p className={styles.requiredNote}>Les champs avec <span className={styles.required}>(*)</span> sont obligatoire.</p>
                    </div>
                    <Submit
                        Foreground={Foreground}
                        Background={Background}
                        HoverColor={HoverColor}
                        FocusColor={FocusColor}
                        text="Envoyer"
                    />
                </form>
            )}
        </div>
    );
}


export default BasicForm;