import React, { useEffect, useRef } from "react";
import styles from "./IconCaptionColumn.module.css";


const IconCaptionColumn = ({ icons }) => {
    const itemRefs = useRef([]);

    useEffect(() => {
        for (let i = 0; i < itemRefs.current.length; i++) {
            itemRefs.current[i].style.animationDelay = `${i * 0.3}s`;
        }

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add(styles.show);
                    } else {
                        entry.target.classList.remove(styles.show);
                    }
                });
            },
            { threshold: 0.1 }
        );

        const iconContainers = document.querySelectorAll(`.${styles.iconContainer}`);
        iconContainers.forEach((iconContainer) => observer.observe(iconContainer));

        return () => {
            observer.disconnect();
        };
    }, []);


    return (
        <div className={styles.container}>
            {icons.map((icon, index) => (
                <div className={styles.iconContainer} key={index} ref={(el) => (itemRefs.current[index] = el)}>
                    <img className={styles.icon} src={icon.icon} alt="" />
                    <div className={styles.title}>{icon.title}</div>
                    <div className={styles.description}>{icon.description}</div>
                </div>
            ))}
        </div>
    );
};


export default IconCaptionColumn;