import React from "react";

import Banner from "../../components/Banner/Banner";
import CallToAction from "../../components/CallToAction/CallToAction";
import FloatingContainer from "../../components/Containers/FloatingContainer/FloatingContainer";
import IconCaptionColumn from "../../components/IconCaption/IconCaptionColumn/IconCaptionColumn";
import ServicesSection from "../../components/ServicesSection/ServicesSection";
import Title from "../../components/Title/Title";

function Ventilation() {
    const titleProps = {
        text: "Installation de nouveaux systèmes de ventilation",
        img: {
            path: "/icons/fields/fan.png",
            alt: "Ventilation",
        },
        containerStyle: {
            width: "90%",
            justifyContent: "left",
        },
        titleStyle: {
            fontSize: "2rem",
            color: "var(--vmc-color)",
            fontWeight: "700",
            textAlign: "left",
        },
        imgStyle: {
            width: "4rem",
            height: "auto",
        },
    };

    const callToActionProps = {
        title: "Améliorez la qualité de l'air chez vous !",
        description: "Contactez-nous pour en savoir plus sur nos services d'installation et de maintenance de VMC.",
        buttonText: "Nous contacter",
        buttonLink: "/contact",
        buttonColor: "var(--vmc-color)",
        buttonHoverColor: "var(--vmc-color-hover)",
    };

    const servicesSectionProps = {
        titleComponent: <Title {...titleProps} />,
        services: [
            {
                title: "Améliorez la qualité de l'air de votre logement",
                description: `Nous proposons des solutions de ventilation adaptées pour assurer un renouvellement de l'air de
                qualité dans votre habitat. Nos experts en ventilation vous accompagnent dans le choix de la solution la plus
                adaptée à vos besoins et exigences.`,

                image: "/images/services/ventilation/VMC_double_flux.jpg",
                imageAlt: "Ventilation pour particuliers",
            },
            {
                title: "Optimisez la qualité de l'air de votre entreprise",
                description: `Notre entreprise offre des solutions de ventilation pour les entreprises et les bâtiments du tertiaire.
                Nous concevons et installons des systèmes de ventilation performants pour garantir un air sain dans les espaces de
                travail et améliorer le confort des utilisateurs. Nos experts en ventilation interviennent dans la conception de votre
                projet pour vous proposer une solution sur mesure.`,

                image: "/images/services/ventilation/VMC_tertiaire.jpg",
                imageAlt: "Ventilation pour le tertiaire",
            },
        ],
    };

    const vmcInlineIconCaption = [
        {
            title: "VMC personnalisés",
            icon: "/icons/ventilation/house.png",
            description: "Systèmes de ventilation sur mesure pour réduire les coûts de chauffage et de climatisation."
        },
        {
            title: "Air propre et sain",
            icon: "/icons/ventilation/health.png",
            description: "Améliorez la santé et la productivité des employés."
        },
        {
            title: "Circulation de l'air efficace",
            icon: "/icons/ventilation/coche.png",
            description: "Optimisez l'efficacité énergétique et la qualité de l'air intérieur."
        },
    ];

    return (
        <div>
            <Banner
                title="Améliorez la qualité de l'air <br>avec nos services de VMC"
                image="/images/banners/VMC.jpg"
                alt="Ventilation"
                borderColor="var(--vmc-color)"
            />
            <FloatingContainer>
                <ServicesSection {...servicesSectionProps} />
                <hr style={{ border: "1px solid #ddd", width: "90%" }} />
                <h2 style={{ marginBottom: "60px" }}>Améliorez votre qualité d'air intérieur</h2>
                <IconCaptionColumn icons={vmcInlineIconCaption} />
            </FloatingContainer>
            <CallToAction {...callToActionProps} />
        </div>
    );
}


export default Ventilation;