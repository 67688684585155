import React, { useState, useRef } from 'react';

import styles from './TagSelector.module.css';


function TagSelector({ tags, onTagsChange }) {
    const [inputValue, setInputValue] = useState('');
    const [selectedTags, setSelectedTags] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const [errorMessages, setErrorMessages] = useState('');

    const dropdownRef = useRef(null);
    const inputRef = useRef(null);

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleTagClick = (tag) => {
        if (!selectedTags.includes(tag)) {
            const updatedTags = [...selectedTags, tag];
            setSelectedTags(updatedTags);
            setErrorMessages('');
            setInputValue('');

            setIsDropdownOpen(false);
            onTagsChange(updatedTags);
        }
        else {
            setErrorMessages('Le tag existe déjà');
            inputRef.current.blur();
        }
    };

    const handleRemoveTag = (tag) => {
        const updatedTags = selectedTags.filter((selectedTag) => selectedTag !== tag);
        setSelectedTags(updatedTags);
        onTagsChange(updatedTags);
    };

    const filteredTags = tags.filter((tag) =>
        tag.toLowerCase().includes(inputValue.toLowerCase())
    );

    const handleInputFocus = () => {
        setIsDropdownOpen(true);
    };

    const handleInputBlur = () => {
        setTimeout(() => {
            setIsDropdownOpen(false);
        }, 200);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent form submission

            if (inputValue.trim() === '') {
                setErrorMessages('Le tag ne peut pas être vide');
                inputRef.current.blur(); // Unfocus the input
                return;
            }

            if (selectedTags.includes(inputValue)) {
                setErrorMessages('Le tag existe déjà');
                inputRef.current.blur();
                return;
            }

            if (!tags.includes(inputValue)) {
                setErrorMessages('Le tag n\'existe pas');
                inputRef.current.blur();
                return;
            }

            handleTagClick(inputValue.trim());
            setErrorMessages('');
            inputRef.current.blur();
        }
    };


    return (
        <div className={styles.tagSelector}>
            <div className={styles.inputContainer}>
                <input
                    type="text"
                    placeholder="Entrer pour filtrer les tags"
                    value={inputValue}
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    onKeyDown={handleKeyDown}
                    ref={inputRef}
                />

                {errorMessages !== '' && <div className={styles.errorMessage}>{errorMessages}</div>}
                {isDropdownOpen && (
                    <div className={styles.dropdown} ref={dropdownRef}>
                        {(inputValue === '' ? tags : filteredTags).map((tag) => (
                            <button
                                key={tag}
                                className={styles.dropdownItem}
                                onClick={() => handleTagClick(tag)}
                            >
                                {tag}
                            </button>
                        ))}
                    </div>
                )}
            </div>

            <div className={styles.selectedTags}>
                {selectedTags.map((tag) => (
                    <span key={tag} className={styles.selectedTag}>
                        {tag}
                        <button
                            className={styles.removeTagButton}
                            onClick={() => handleRemoveTag(tag)}
                        >
                            &times;
                        </button>
                    </span>
                ))}
            </div>
        </div>
    );
}

export default TagSelector;
