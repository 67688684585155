import React, { useState, useRef } from "react";
import BasicForm from "../BasicForm/BasicForm";
import styles from "./FloatingContactForm.module.css";


function FloatingContactForm({ Foreground, Background, HoverColor, FocusColor }) {
    const [isOpen, setIsOpen] = useState(false);
    const floatingFormRef = useRef(null);

    const handleFloatingFormState = () => {
        if (!isOpen) {
            setIsOpen(true);
            if (floatingFormRef.current) floatingFormRef.current.classList.remove(styles.animateClose);
        } else {
            floatingFormRef.current.classList.add(styles.animateClose);
            setTimeout(() => {
                setIsOpen(false);
            }, 600);
        }
    };


    return (
        <>
            <div className={styles.floatingContactForm}>
                {isOpen && (
                    <div ref={floatingFormRef} className={styles.floatingContactFormContent}>
                        <BasicForm
                            Foreground={Foreground}
                            Background={Background}
                            HoverColor={HoverColor}
                            FocusColor={FocusColor}
                        />
                    </div>
                )}
            </div>
            <button className={styles.openButton} onClick={handleFloatingFormState}>
                <svg xmlns="http://www.w3.org/2000/svg" width="800px" height="800px" viewBox="0 0 24 24" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M12 6C9.79086 6 8 7.79086 8 10C8 12.2091 9.79086 14 12 14C14.2091 14 16 12.2091 16 10C16 7.79086 14.2091 6 12 6Z" fill={Background} />
                    <path fillRule="evenodd" clipRule="evenodd" d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 14.0289 19.2447 15.8813 18.0001 17.2916C16.4085 15.8674 14.3052 15 12.0002 15C9.69511 15 7.5917 15.8675 6.00015 17.2918C4.75533 15.8815 4 14.029 4 12Z" fill={Background} />
                </svg>
            </button>
        </>
    );
}


export default FloatingContactForm;