import React from "react";
import styles from "../Slider.module.css";


function SliderDots({ slides, currentSlide, goToSlide }) {
  return (
    <div className={styles.slider__dots}>
      {slides.map((_, index) => (
        <div
          key={index}
          className={
            styles.slider__dot +
            " " +
            (index === currentSlide ? styles.slider__dotActive : "")
          }
          onClick={() => goToSlide(index)}
        ></div>
      ))}
    </div>
  );
}


export default SliderDots;