import React from "react";

import Banner from "../../components/Banner/Banner";
import CallToAction from "../../components/CallToAction/CallToAction";
import FloatingContainer from "../../components/Containers/FloatingContainer/FloatingContainer";
import RelativeWaveContainer from "../../components/Containers/RelativeWaveContainer/RelativeWaveContainer";
import IconCaptionColumn from "../../components/IconCaption/IconCaptionColumn/IconCaptionColumn";
import ServicesSection from "../../components/ServicesSection/ServicesSection";
import Title from "../../components/Title/Title";

function Climatisation() {

    const titleProps = {
        text: "Installation et maintenance de sytemes de climatisation et de froid",
        img: {
            path: "/icons/fields/winter.png",
            alt: "Climatisation",
        },
        containerStyle: {
            width: "90%",
            justifyContent: "left",
        },
        titleStyle: {
            fontSize: "2rem",
            color: "var(--climatisation-color-hover)",
            fontWeight: "700",
            textAlign: "left",
        },
        imgStyle: {
            width: "4rem",
            height: "auto",
        },
    };

    const servicesSectionProps = {
        titleComponent: <Title {...titleProps} />,
        services: [
            {
                title: "Installation de climatisations",
                description: `Nous proposons des solutions de climatisation adaptées pour assurer un renouvellement de l'air de
                qualité dans votre habitat. Nos experts en climatisation vous accompagnent dans le choix de la solution la plus
                adaptée à vos besoins et exigences.`,
                image: "/images/services/froid/climatisation.jpg",
                imageAlt: "Climatisation",
            },
            {
                title: "Installation de systèmes de froid",
                description: `Notre entreprise offre des solutions de froid pour les entreprises et les bâtiments du tertiaire.
                Nous concevons et installons des systèmes de froid performants pour garantir un air sain dans les espaces de
                travail et améliorer le confort des utilisateurs. Nos experts en froid interviennent dans la conception de votre
                projet pour vous proposer une solution sur mesure.`,
                image: "/images/services/froid/froid.jpg",
                imageAlt: "Froid",
            },
        ],
    };

    const inlineIconCaptionProps = [
        {
            title: "Climatisations personnalisées",
            icon: "/icons/froid/air-frais.png",
            description: "Systèmes de climatisation sur mesure pour réduire les coûts de chauffage et de climatisation."
        },
        {
            title: "Fiabilité et durabilité",
            icon: "/icons/froid/bouclier.png",
            description: "Un système de climatisation fiable et durable pour un confort optimal."
        },
        {
            title: "Confort et économies",
            icon: "/icons/froid/euro.png",
            description: "Systèmes de climatisation performants pour un confort optimal et des économies d'énergie."
        },
    ];


    const callToActionProps = {
        title: "Vous souhaitez en savoir plus ?",
        description: "Contactez-nous pour en savoir plus sur nos services d'installation et de maintenance de climatisations et de systèmes de froid.",
        buttonText: "Nous contacter",
        buttonLink: "/contact",
        buttonColor: "var(--climatisation-color)",
        buttonHoverColor: "var(--climatisation-color-hover)",
    };

    return (
        <div>
            <Banner
                title="Améliorez votre système avec nos solutions<br> de climatisations & froid"
                image="/images/banners/climatisation.jpg"
                alt="Climatisation"
                borderColor="var(--climatisation-color)"
            />
            <FloatingContainer>
                <ServicesSection {...servicesSectionProps} />
            </FloatingContainer>
            <RelativeWaveContainer backgroundColor="#ccffff">
                <Title
                    text="Améliorez votre système avec nos solutions de climatisations & froid"
                    titleStyle={{ fontSize: "2rem", marginBottom: "60px" }}
                    containerStyle={{ width: "60%", margin: "0 auto" }}
                />
                <IconCaptionColumn icons={inlineIconCaptionProps} />
            </RelativeWaveContainer>
            <CallToAction {...callToActionProps} />
        </div >
    );
}

export default Climatisation;