import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";

import styles from "./InlineLinkable.module.css";

function InlineLinkable({ fields }) {
    const itemRefs = useRef([]);

    useEffect(() => {
        for (let i = 0; i < itemRefs.current.length; i++) {
            itemRefs.current[i].style.animationDelay = `${i * 0.3}s`;
        }

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add(styles.show);
                    } else {
                        entry.target.classList.remove(styles.show);
                        entry.target.style.opacity = "0";
                    }
                });
            },
            { threshold: 0.1 }
        );

        itemRefs.current.forEach((itemRef) => observer.observe(itemRef));

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <div className={styles.fields}>
            {fields.map((field, index) => (
                <div key={index} className={styles.item} ref={(el) => (itemRefs.current[index] = el)}>
                    <NavLink to={field.route} className={styles.field} exact="true">
                        <img src={field.icon} alt={field.title} />
                    </NavLink>

                    <NavLink to={field.route} className={styles.field} exact="true">
                        <h3>{field.title}</h3>
                    </NavLink>
                </div>
            ))}
        </div>
    );
}


export default InlineLinkable;