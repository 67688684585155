import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from '../NavigationBar.module.css';


function SubLinks({ subLinks, closeLeftMenu }) {

    return (
        <ul className={styles.subMenu}>
            {subLinks.map((subLink, index) => (
                <li key={index}>
                    <NavLink to={subLink.path} className={styles.subMenuLink} onClick={closeLeftMenu}>{subLink.text}</NavLink>
                </li>
            ))}
        </ul>
    );
}

export default SubLinks;