import React, { useState } from 'react';
import Radio from '../Radio/Radio';
import styles from './GroupRadio.module.css';

function GroupRadio({ options, onChange, defaultValue, checkMany = false }) {
    const [selectedValues, setSelectedValues] = useState([defaultValue]);

    const handleRadioChange = (value) => {
        if (checkMany) {
            let newSelectedValues;
            if (selectedValues.includes(value)) {
                // Deselect the value if it's already selected
                newSelectedValues = selectedValues.filter((val) => val !== value);
            } else {
                // Select the value if it's not already selected
                newSelectedValues = [...selectedValues, value];
            }
            setSelectedValues(newSelectedValues);
            onChange(newSelectedValues);
        }
        else if (selectedValues[0] !== value) {
            // Deselect the previous value and select the new value
            setSelectedValues([value]);
            onChange([value]);
        }
    };

    return (
        <div className={styles.groupRadio}>
            {options.map((option) => (
                <Radio
                    key={option.id}
                    id={option.id}
                    value={option.value}
                    label={option.label}
                    checked={selectedValues.includes(option.id)}
                    onChange={() => handleRadioChange(option.id)}
                />
            ))}
        </div>
    );
}


export default GroupRadio;