import React from "react";

import MainLinks from "../Links/MainLinks";

import styles from "./LeftMenu.module.css";


function LeftMenu({ links, handleCloseMenu }) {

    return (
        <div className={styles.leftMenu}>
            <button className={styles.closeButton} onClick={handleCloseMenu}>
                <span className={styles.closeIcon}>&times;</span>
            </button>
            <img className={styles.logo} src="/logo.png" alt="Logo de l'entreprise" />
            <ul className={styles.leftMenuList}>
                <MainLinks links={links} closeLeftMenu={handleCloseMenu} />
            </ul>
            <button className={styles.ctaButton}>Devis Gratuit</button>
        </div>
    );
}

export default LeftMenu;
