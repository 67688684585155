import React from "react";

import styles from "./GridContainer.module.css";


function GridContainer({columns, rows, children, backgroundColor, color}) {
    const gridTemplateColumns = columns ? columns : "1fr";
    const gridTemplateRows = rows ? rows : "1fr";

    return (
        <div className={styles.gridContainer} style={{ gridTemplateColumns, gridTemplateRows, backgroundColor, color }}>
            {children}
        </div>
    );
}

export default GridContainer;