import React from "react";

import Banner from "../components/Banner/Banner";
import GridContainer from "../components/Containers/GridContainer/GridContainer";
import RelativeContainer from "../components/Containers/RelativeContainer/RelativeContainer";
import DetailedForm from "../components/Contact/DetailedForm/DetailedForm";
import IconLinkRow from "../components/IconCaption/IconLinkRow/IconLinkRow";


function Contact() {

    const icons = [
        {
            icon: "/icons/contact/location.png",
            alt: "Adresse",
            link: "https://goo.gl/maps/9ZGJ9Z9ZJ9ZJ9ZJ9Z",
            text: "15 rue de patroa, 42100 Saint-Etienne"
        },
        {
            icon: "/icons/contact/phone.png",
            alt: "Téléphone",
            link: "tel:+33612345678",
            text: "06 12 34 56 78"
        },
        {
            icon: "/icons/contact/email.png",
            alt: "Email",
            link: "mailto:contact@cvcconcept.com",
            text: "contact@cvcconcept.com"
        },

        {
            icon: "/icons/contact/website.png",
            alt: "Site web",
            link: "https://www.cvcconcept.com",
            text: "www.cvcconcept.com"
        }
    ];

    return (
        <div>
            <Banner
                title="Contactez nous"
                image="/images/banners/contact.png"
                alt="Contactez nous"
            />

            <GridContainer columns="1fr 1fr" backgroundColor="var(--background-color)" color="var(--text-color-dark)">
                <RelativeContainer darkContainer={true}>
                    <h2 style={{ marginTop: "60px", textAlign: "center" }}>Prenez contact avec nous dès maintenant</h2>
                    <p style={{ textAlign: "center", width: "95%", margin: "0 auto", marginBottom: "60px" }}>
                        Nous sommes là pour vous aider à trouver des solutions qui répondent à vos besoins.
                        N'hésitez pas à nous contacter pour toute demande d'information ou de devis, par téléphone, par email ou en remplissant le formulaire.
                    </p>

                    <IconLinkRow icons={icons} />
                </RelativeContainer>

                <RelativeContainer darkContainer={false}>
                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "flex-start" }}>
                        <DetailedForm />
                    </div>
                </RelativeContainer>
            </GridContainer>
        </div>
    );
}

export default Contact;