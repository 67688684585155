import React from 'react';
import styles from './Title.module.css';

function Title({ text, img, containerStyle, titleStyle, imgStyle }) {


    return (
        <div className={styles.title} style={containerStyle}>
            {img &&
                <img src={img.path} alt={img.alt} style={imgStyle} />
            }
            <h2 style={titleStyle} dangerouslySetInnerHTML={{ __html: text }} />
        </div>
    );
}


export default Title;