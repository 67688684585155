import React from "react";
import { NavLink } from "react-router-dom";

import styles from "./Footer.module.css";


function Footer({ links }) {

    return (
        <footer className={styles.footer}>
            <div className={styles.container}>
                <div className={styles.logoContainer}>
                    <img src="/logo.png" alt="Logo" className={styles.logo} />
                    <div className={styles.copyRight}>
                        <p>&copy; 2023 CVC Concept</p>
                    </div>
                </div>
                <div className={styles.services}>
                    <h3>Nos Solutions</h3>
                    {links.map((child, index) => {
                        return <NavLink key={index} to={child.path} className={styles.link}>{child.text}</NavLink>
                    })
                    }
                </div>

                <div className={styles.contactInfo}>
                    <a href="tel:0660599835">
                        <p className={styles.tel}>06 60 59 98 35</p>
                    </a>
                    <a href="mailto:contact@cvconcept.com">
                        <p className={styles.email}>contact@cvconcept.com</p>
                    </a>

                    <p>CVC Concept</p>
                    <p>15 rue patroa</p>
                    <p>42100 Saint-Etienne</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
