import React from "react";
import styles from "./ServicesSection.module.css";


function ServicesSection({ titleComponent, services }) {
    return (
        <div className={styles.servicesSection}>
            {titleComponent}
            <div className={styles.servicesContainer}>
                {services.map((service, index) => (
                    <div
                        className={styles.service}
                        key={index}
                        style={{
                            flexDirection: index % 2 === 0 ? "row" : "row-reverse",
                        }}
                    >
                        <div className={`${styles.serviceImage} ${index % 2 === 0 ? styles.left : styles.right}`}>
                            <img src={service.image} alt={service.imageAlt} />
                        </div>
                        <div className={styles.serviceContent}>
                            <h3>{service.title}</h3>
                            <p>{service.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div >
    );
}


export default ServicesSection;