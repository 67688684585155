import React, { useState, useEffect } from 'react';
import styles from './Radio.module.css';

function Radio({ id, value, onChange, checked, label }) {
    const [isCheck, setIsCheck] = useState(checked);

    useEffect(() => {
        setIsCheck(checked);
    }, [checked]);

    const handleButtonState = () => {
        setIsCheck(!isCheck);
    };

    return (
        <div className={styles.radio}>
            <input
                type="radio"
                id={id}
                value={value}
                onChange={onChange}
                onClick={handleButtonState}
                checked={isCheck}
            />
            <label className={styles.label} htmlFor={id}>
                {label}
            </label>
        </div>

    );
}

export default Radio;
