import React from 'react';
import { FaPhone, FaEnvelope } from 'react-icons/fa';

import styles from './Header.module.css';

const Header = () => {
    return (
        <div className={styles.header}>
            <div className={styles.container}>

                <div className={styles.iconContainer}>
                    <a href="tel:0660599835">
                        <FaPhone className={styles.FaPhone} />
                        <p className={styles.phone}>06 60 59 98 35</p>
                    </a>
                </div>
                <div className={styles.iconContainer}>
                    <a href="mailto:contact@cvconcept.com">
                        <FaEnvelope className={styles.FaEnvelope} />
                        <p className={styles.email}>contact@cvconcept.com</p>
                    </a>
                </div>
            </div>

        </div>
    );
};

export default Header;
