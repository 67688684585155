import React from "react";

import Banner from "../components/Banner/Banner";
import CallToAction from "../components/CallToAction/CallToAction";
import Card from "../components/Card/Card";
import FloatingContainer from "../components/Containers/FloatingContainer/FloatingContainer";
import RelativeContainer from "../components/Containers/RelativeContainer/RelativeContainer";
import RelativeWaveContainer from "../components/Containers/RelativeWaveContainer/RelativeWaveContainer";
import Description from "../components/Description/Description";
import IconCaptionColumn from "../components/IconCaption/IconCaptionColumn/IconCaptionColumn";
import InlineLinkable from "../components/InlineLinkable/InlineLinkable";
import Slider from "../components/Slider/Slider";
import Title from "../components/Title/Title";


function Home() {
    const sliderTitle = "Nos certifications Qualité";
    const slides = [
        {
            alt: "QualiPV RGE",
            image: "/images/qualifications/qualipv.png",
        },
        {
            alt: "QualiPAC RGE",
            image: "/images/qualifications/qualipac.png",
        },
        {
            alt: "QualiSol RGE",
            image: "/images/qualifications/qualisol.png",
        },
        {
            alt: "Qualibat RGE", // alt text for the image
            image: "/images/qualifications/qualibat.png",
        }
    ];


    const title = "Des solutions adaptées à vos besoins";
    const description = `Vous voulez améliorer l'efficacité énergétique de votre bâtiment commercial ou industriel ?
    CVC Concept est là pour vous ! Notre entreprise est spécialisée dans l'installation et la rénovation des systèmes de
    chauffage, ventilation, climatisation (froid) et panneaux solaires les plus performants du marché. Avec nos équipements de pointe,
    vous pourrez réduire vos coûts énergétiques et augmenter votre confort.<br /><br /> 
    
    Mais chez CVC Concept, nous allons plus loin que ça. Nous sommes engagés dans la transition énergétique et c'est pour cela que nous proposons des solutions
    solaires pour alimenter votre bâtiment en énergie propre. Vous pourrez ainsi réduire votre empreinte carbone et faire des économies 
    sur vos factures d'énergie. <br /><br /> 
    
    Nous sommes des experts dans notre domaine et nous utilisons des techniques et des technologies
    écologiques pour créer des solutions durables et respectueuses de l'environnement. Nous sommes fiers de contribuer à une société plus verte et plus durable.
    N'attendez plus pour améliorer l'efficacité énergétique de votre bâtiment. Contactez-nous dès maintenant pour une consultation gratuite et sans engagement.
    Nous serons ravis de vous aider à atteindre vos objectifs énergétiques.`;


    const fields = [
        {
            title: "Énergie Solaire",
            icon: "/icons/fields/sun.png",
            route: "services/energie-solaire",
        },
        {
            title: "Froid et Climatisation",
            icon: "/icons/fields/winter.png",
            route: "services/froid-climatisation",
        },
        {
            title: "Ventilation",
            icon: "/icons/fields/fan.png",
            route: "services/ventilation",
        },
        {
            title: "Chauffage",
            icon: "/icons/fields/fire.png",
            route: "services/chauffage",
        }
    ];

    const components = [
        {
            Component: InlineLinkable,
            props: {
                fields,
            },
        },
        {
            Component: Description,
            props: {
                title,
                description,
                image: {
                    src: "/icons/home/office-repair-maintenance.png",
                    alt: "Chauffage",
                    left: true,
                },
            },
        },
    ];

    const cards = [
        {
            Component: Card,
            props: {
                title: "Panneau Solaire",
                description: "Les panneaux solaires transforment l'énergie du soleil en électricité propre et renouvelable pour alimenter votre maison ou votre entreprise.",
                image: "/images/backgrounds/panneau_solaire.jpeg",
                alt: "Paneeau Solaire",
                path: "services/energie-solaire"
            },
        },
        {
            Component: Card,
            props: {
                title: "Froid et Climatisation",
                description: "Rafraîchissez votre espace de vie avec la climatisation tout en économisant de l'énergie. Optez pour une solution performante et profitez d'un été frais et agréable.",
                image: "/images/backgrounds/climatisation.jfif",
                alt: "Climatisation & Froid",
                path: "services/froid-climatisation"
            },
        },
        {
            Component: Card,
            props: {
                title: "Ventilation",
                description: "La ventilation est un élément essentiel de tout bâtiment. Elle permet de renouveler l'air intérieur et d'évacuer les polluants et les odeurs.",
                image: "/images/backgrounds/ventilation.jpg",
                alt: "Ventilation",
                path: "services/ventilation"
            },
        },
        {
            Component: Card,
            props: {
                title: "Chauffage",
                description: "Chauffer mieux, c’est plus de chaleur mais moins de consommation. Là esttout l’enjeu de votre changement demode de chauffage.",
                image: "/images/backgrounds/chauffage.jpg",
                alt: "Chauffage",
                path: "services/chauffage"
            },
        },
    ]

    const createCards = (cards) => {
        return cards.map((card, index) => {
            return <Card key={index} {...card.props} />;
        });
    };

    const callToActionProps = {
        title: "Contactez-nous dès maintenant",
        description: "Nous sommes là pour vous aider à trouver des solutions qui répondent à vos besoins.",
        buttonText: "Nous contacter",
        buttonLink: "/contact",
        buttonColor: "var(--primary-color)",
        buttonHoverColor: "var(--secondary-color)",
    };

    const inlineIconCaptionProps = [
        {
            title: "Solutions personnalisées",
            icon: "/icons/home/solution.png",
            description: "Des solutions sur mesure pour répondre aux besoins spécifiques de votre entreprise.",
        },
        {
            title: "Rentabilité économique",
            icon: "/icons/home/croissance-monetaire.png",
            description: "Des solutions d'énergie solaire rentables pour réduire vos coûts énergétiques à long terme.",
        },
        {
            title: "Responsabilité environnementale",
            icon: "/icons/home/planete-terre.png",
            description: "Contribuez à la préservation de l'environnement avec des solutions d'énergie solaire respectueuses.",
        },
    ];

    return (
        <div>
            <Banner
                title="Amélioration énergétique<br />de l’habitat"
                image="/images/banners/home.webp"
                alt="Amélioration énergétique de l’habitat"
            />
            <FloatingContainer >
                {components.map((component, index) => {
                    return <component.Component key={index} {...component.props} />;
                })}
            </FloatingContainer>
            <RelativeWaveContainer backgroundColor="#e1e1e1">
                <Title
                    text="Des solutions sur mesure pour votre entreprise"
                    titleStyle={{ fontSize: "2rem" }}
                />
                <IconCaptionColumn icons={inlineIconCaptionProps} />
            </RelativeWaveContainer>
            <div style={{ marginBottom: "60px" }}>
                <Slider slides={slides} title={sliderTitle} />
            </div>
            <RelativeContainer>
                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "flex-start" }}>
                    {createCards(cards)}
                </div>
            </RelativeContainer>

            <div style={{ marginTop: "60px" }}>
                <CallToAction {...callToActionProps} />
            </div>
        </div>
    );

}

export default Home;