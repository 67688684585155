import React, { useState } from 'react';
import styles from './TextArea.module.css';

function TextArea({ id, value, onChange, style, required, label, placeholder, rows }) {
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const textAreaStyle = {
        ...style.input,
        ...(isFocused ? style.focus : style.blur),
    };

    const containerStyle = { ...style.container };


    return (
        <div style={containerStyle} className={styles.textAreaContainer}>
            <label htmlFor={id}>{label}<span className={styles.required}>*</span></label>
            <textarea
                className={styles.textArea}
                id={id}
                value={value}
                onChange={onChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                style={textAreaStyle}
                placeholder={placeholder}
                required={required}
                rows={rows}
            ></textarea>
        </div>
    );
}

export default TextArea;
