import React, { useState, useRef } from "react";

import styles from "./Slider.module.css";

import SliderItem from "./SliderElements/SliderItem";
import SliderControls from "./SliderElements/SliderControls";
import SliderDots from "./SliderElements/SliderDots";


function Slider({ slides, title, displayDots = true, displayControls = true, numberOfSlides = 3 }) {

    const [currentSlide, setCurrentSlide] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [dragStart, setDragStart] = useState(0);
    const [dragEnd, setDragEnd] = useState(0);
    const sliderRef = useRef(null);

    const handleTouchStart = (event) => {
        setIsDragging(true);
        setDragStart(event.touches[0].clientX);
    };

    const handleTouchMove = (event) => {
        if (isDragging) {
            setDragEnd(event.touches[0].clientX);
        }
    };

    const handleTouchEnd = () => {
        setIsDragging(false);
        if (dragEnd < dragStart) {
            nextSlide();
        } else if (dragEnd > dragStart) {
            prevSlide();
        }
    };

    const goToSlide = (index) => {
        setCurrentSlide(index);
    };

    const nextSlide = () => {
        setCurrentSlide((currentSlide + 1) % slides.length);
    };

    const prevSlide = () => {
        setCurrentSlide((currentSlide - 1 + slides.length) % slides.length);
    };

    return (
        <div className={styles.slider} ref={sliderRef}>
            <h2 className={styles.slider__title}>{title}</h2>
            <div className={styles.slider__container}>
                <div
                    className={styles.slider__wrapper}
                    style={{
                        transform: `translateX(-${currentSlide * (100 / numberOfSlides)}%)`,
                    }}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                >
                    <SliderItem slides={slides} numberOfSlides={numberOfSlides} />
                </div>

                {displayControls &&
                    <SliderControls prevSlide={prevSlide} nextSlide={nextSlide} />
                }
            </div>

            {displayDots &&
                <SliderDots slides={slides} currentSlide={currentSlide} goToSlide={goToSlide} />
            }
        </div >
    );
}

export default Slider;
