import React from "react";
import styles from "../Slider.module.css";


function SliderItem({ slides, numberOfSlides }) {
  return (
    <>
      {slides.map((slide, index) => (

        <div
          key={index}
          className={styles.slider__item}
          style={{ flexBasis: `${100 / numberOfSlides}%` }}
        >
          <img src={slide.image} alt={slide.alt} className={styles.slider__image} />
        </div>
      ))}
    </>
  );
}


export default SliderItem;