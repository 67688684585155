import React from "react";
import { NavLink } from "react-router-dom";

import styles from "./IconLinkRow.module.css";


function IconLinkRow({ icons }) {
    return (
        <div className={styles.iconLinkRowContainer}>
            {icons.map((icon, index) => (
                <div className={styles.iconContainer} key={index}>
                    <NavLink className={styles.icon} to={icon.link}>
                        <img className={styles.icon} src={icon.icon} alt={icon.alt} />
                    </NavLink>
                    <NavLink className={styles.link} to={icon.link}>{icon.text}</NavLink>
                </div>
            ))}
        </div>
    );
}

export default IconLinkRow;