import React, { useState } from "react";

import styles from "./CallToAction.module.css";

import { NavLink } from "react-router-dom";

function CallToAction({ title, description, buttonText, buttonLink, buttonColor, buttonHoverColor }) {

    const styleButtonColor = buttonColor ? { backgroundColor: buttonColor } : {};
    const styleButtonHoverColor = buttonHoverColor ? { backgroundColor: buttonHoverColor } : {};

    const [buttonHover, setButtonHover] = useState(false);

    const handleMouseEnter = () => {
        setButtonHover(true);
    };

    const handleMouseLeave = () => {
        setButtonHover(false);
    };

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <h2 className={styles.title}>{title}</h2>
                <p className={styles.description}>{description}</p>
                <NavLink to={buttonLink} className={styles.button}
                    exact="true"
                    style={buttonHover ? styleButtonHoverColor : styleButtonColor}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    {buttonText}
                </NavLink>
            </div>
        </div>
    );
}


export default CallToAction;