import React from "react";

import styles from "./FloatingContainer.module.css";


function FloatingContainer({ children }) {
    return <div className={styles.floatingContainer}>{children}</div>;
}


export default FloatingContainer;